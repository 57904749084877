import React, { useState } from "react";
import ".././assets/css/Navbar.css";
import homeslider from'.././assets/img/bg_imgs.jpg';
import card1 from'.././assets/img/s1.webp';
import card2 from'.././assets/img/s2.jpg';
import graphic1 from ".././assets/img/arrow-8.png";
import graphic2 from ".././assets/img/arrow-7.png";

function Navbar() {
const [isActive, setActive] = useState("false");

const ToggleClass = () => {
setActive(!isActive);
 if (document.body.classList.contains('bodyscroll')){
  document.body.classList.remove('bodyscroll')
 } else {
  document.body.classList.add('bodyscroll')
 }
};
return (
<>
 
<div className="containers text-left topbar-details">
    <div className="row g-0">
    <div className="col-9">
    <a className="topdetails"
          href="cdn-cgi/l/email-protection.html#d2b1b3a0b792a0b7b6a1b1bda0a2bbbdbca1b7b1a7a0bba6abfcbbbc">
          <img style={{ height: '23px'}} src="https://i.pinimg.com/originals/83/8e/0d/838e0d9023e1ef9ee40e3bce11235e70.png"
            alt="mails"></img>care@redscorpionsecurity.in
        </a>
        <a className="topdetails" href="/">
          <img style={{height: '23px'}} src="mail.svg" alt="mails"></img><strong>+91 123456789</strong>
        </a>
        <a className="topdetails" href="/">
          <img style={{height: '23px'}} src="mail.svg" alt="mails"></img>84, Sundar Nagar, New Delhi 110003 </a>
      </div>

      <div className="col">
        <div className="social-m-logo" style={{display: 'inline', float: 'right'}}>
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-128.png" alt="clipart"></img>
          
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-128.png"alt="clipart"></img>
          
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter3_colored_svg-128.png"alt="clipart"></img>
          
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Tumblr2_colored_svg-128.png"alt="clipart"></img>
          
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Youtube_colored_svg-128.png"alt="clipart"></img>
        </div>
      </div>

    </div>
  </div>



 
<header className={isActive ? "containers" : "containers headbg" }>
<div className="containernav">
    <div><div onClick={ToggleClass} className={isActive ? "clsbtn" : "clsbtn-o"}><i className={isActive ? "fa fa-bars" : "fa fa-close" }></i></div>
    <img className={isActive ? "logo" : "logo-h" } src="https://redscorpionsecurity.in/wp-content/uploads/2019/06/red-scorpion-security-logo.png" alt="log"></img>
    </div>  
<nav className={isActive ? "navbarsm responsm" : "navbarsm" }>
  <ul>
    <li><a href="/#">Home</a></li>
    <li><a href="/#">Aboout us</a></li>
    <li><a href="/#">Services</a>
        <ul className="drop-downsm">
            <li><img src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/ico-services-48x48.png"alt="..."></img><a href="/#">All Services</a></li>
            <li><img src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/01-ico-guard-48x48.png"alt="..."></img><a href="/#">Security Guard</a></li>
            <li><img src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/ico-services-48x48.png"alt="..."></img><a href="/#">Security Supervisor</a></li> <li><a href="/#">Bouncer</a></li>
            <li><a href="/#">Armed PSO</a></li>
            <li><a href="/#">Bouncer</a></li>
            <li><a href="/#">Gunman</a></li>
            <li><a href="/#">Safety Expert</a></li>
            <li><a href="/#">ASO</a></li>
            <li><a href="/#">Surveillance Room</a></li>
        </ul>
    </li>
  <li><a href="/#">Clients</a>
        <ul className="drop-downsm">
            <li><a href="/#">tata</a></li>
            <li><a href="/#">zohu</a></li>
            <li><a href="/#">31</a></li>
        </ul>
    </li>
    <li><a href="/#">Training</a></li>
    <li><a href="/#">Carrier</a></li>
    <li><a href="/#">Contact us</a></li>
    <li><a href="/#">Blog</a></li>
  </ul>  
</nav>
</div>
</header>

<div className="container emptyspacefordesktop">   
</div>
<div className="home-slideru">
  <div id="carouselExampleIndicators" className="carousel slide ">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
        aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src={homeslider} className="d-block w-100" alt="bg_imgs.jpg"></img>
      </div>
      <div className="carousel-item">
        <img src={homeslider} className="d-block w-100" alt="bg_imgs.jpg"></img>
      </div>
      <div className="carousel-item">
        <img src={homeslider} className="d-block w-100" alt="..."></img>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div>

<div className="whitebox-top">
  <div className="whitebox-content">
    <div className="row">
      <div className="col-2">
        <img style={{ height: '53px',padding: '8px',margintop: '15px'}} src="mail.svg" alt="mail"></img>
      </div>
      <div className="col">
        <h2><strong>+91 8711021102</strong></h2>
        <p style={{ fontsize: '10px',fontweight: '400',texttransform: 'uppercase', color: "#768492"}}>CALL FOR URGENT SERVICES</p>
      </div>
    </div>
  </div>

  <p id="naqs"><strong>Need a Quality Security<br /> Services for Your Business</strong> </p>
  <button className="topred-button">Contact Now</button>
</div>

<div className="y-section">
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bOPEQWF6fWY" title="Red Scorpion Security Solutions | Security Guard, Security Services in Noida, Delhi NCR Gurgaon Pune" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe> 
</div>

<div className="containers section3-t">
    <p><strong>Range of Security Services</strong> </p>
  </div>


  
  <div className="containers">
    <div className="row g-0">
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card2} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src={card1} className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
      <div className="col-8 col-lg-2" id="cardspaces">
        <div className="card hov">
          <img src="s2.jpg" className="card-img-top"
            alt="..."></img>
          <div className="card-body">
            <div className="cardover-box"><img
                src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/armed-pso-1.jpg" className="card-img-top"
                alt="..."></img></div>
            <h5 className="card-title">Armed PSO</h5>
            <p className="card-text"></p>
            <a className="read-more" href="/#">Read More</a>
          </div>
        </div>
      </div>
    </div>

  </div>




  <div className="row g-0">
   <div className="col-md-6 col-12 tcolor-s">
     <img className="tcolor-hover1" alt="..." src={graphic1}></img>
     <img className="tcolor-hover2" alt='...' src={graphic1}></img>
     <p className="tcolor-t"><strong>Diverse <br /> Sectors</strong> </p>
     <p className="tcolor-p">Red Scorpion Security caters services in diverse sectors through their experiences and
       specialization. The sectors we are rendering are renowned in their respective industries like Automobile, F&B,
       Finance, Electronics, Real Estate, Healthcare & Education, etc.</p>

     <section className="tcolor-slider">

     </section>
   </div>
   <div className="col tcolor-sb">
   </div>
 </div>




 <div>
    <h1 className='our-client'>Our clients</h1>
  </div>
  <div className="container containerls h-100">
    <div className="row align-items-center h-100">
      <div className="container containerls rounded">
        <div className="sliderls">
          <div className="logos">
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
            <i><img style={{height: '80px'}} alt=".." src="https://cdn-igmgldj.nitrocdn.com/CyZAVVlGWFLDqGokrEsoyVonvLqDilTx/assets/images/optimized/rev-5a1ce5b/redscorpionsecurity.in/wp-content/uploads/2023/11/FB-Retail-Sector-08.jpg"></img></i>
           
          </div>
          <div className="logos">
            <i className="fab fa-js fa-4x"></i>
            <i className="fab fa-linkedin-in fa-4x"></i>
            <i className="fab fa-medium-m fa-4x"></i>
          </div>
        </div>
      </div>
    </div>
  
  </div>


  <div className="containers lsscid">
    <h1>Leading Security Services Company in Delhi NCR</h1>
    <p>Red Scorpion Security Solutions is a top-tier, certified, and authorized security agency that provides the best security services in Delhi NCR. We have a crew of adept security guards, armed & unarmed private bodyguards that deal with and provide the best security services to many corporates, commercial, industrial, and business sectors in Delhi and its various nearby regions. Furthermore, we also specialize in safeguarding various educational institutes and college campuses.</p>
    <p>One of the major duties of the professional crew that we have is that they are always dedicated and focused on their jobs. When they are on duty they always carefully observe everything and are always alert and ready to eliminate any kind of risks or problem-causing element that may pose trouble. Security is a fundamental component of our everyday lives therefore we know how crucial it is to remain safe and keep all our assets, premises, and capital protected from any type of dangers. Hence, we are here to ensure the safety of your assets, premises, capital, and other facilities by offering the best and most safety-centric security services by one of the most reliable Security Companies in Delhi NCR.</p>
    <h4>What Makes Us the Best Security Companies in Delhi NCR?</h4>
    <p>It is the dedication, zeal, and constancy of our professionals that make people have faith in our services. Because we know the only way to win people’s hearts and trust is to give them what they expect and desire and that’s what we always aim for which ultimately makes us a first-rate security agency in Delhi. Here take a look at some factors that makes everyone trust us in the long run:</p>
    <li>We strive to deliver first-class and reliable security to your assets and premises and protect your material and digital assets. Our experts recognize and attenuate all types of present or expected hazards with their years of experience and skillfulness.</li>
    <li>Our entire security workforce is selected merely after a thorough training given by professionals who come from some of the reputed backgrounds and possess a bunch of expertise. All our security personnel are well prepared to manage all types of problems with forbearance and adeptness.</li>
    <li>In today’s world, from companies, businesses, commercial areas, industries to even residents, everyone requires 24 hours protection. Therefore, our exceptional security and executive protection aids are organized well to satisfy all your needs and ensure you, your business, workers, physical as well as digital assets everything remains safe always.</li>
    <h4>Why Choose Red Scorpion Security Solutions ?</h4>
    <p>Red Scorpion Security Solutions is a certified, ongoing, and one of the Best Security Companies, delivering exceptional security services at a reasonable price. We always aim to keep the best quality of services by hiring a workforce that is wholly trained and deft and is always keen and committed to their duties and provides the best aid. Since client happiness is our primary purpose, we make sure to never compromise with anything while we are at their assistance. So call us today or contact us online and get the best security services from the industry’s experts in Delhi, Gurgaon, Noida, Faridabad, Lucknow, and Pune.</p>
  </div>


  <div id="red-bar">
    <div id="space-top-redbar"></div>
    <div className="row g-0">
      <div className="col-6 col-lg-3"><h1>18</h1>
        <p>Years of Next Level Security</p></div>
      <div className="col-6 col-lg-3"><h1>2,100</h1>
        <p>Percent Growth Rate</p></div>
      <div className="col-6 col-lg-3"><h1>16</h1>
        <p>Locations</p></div>
      <div className="col-6 col-lg-3"><h1>6</h1>
        <p>States Presence</p></div>
    </div>
  </div>

  <div>
    <div id="ahsrtd" className="row g-0">
      <div style={{ position: 'relative'}} className="col-12 col-lg-6">
      <img className="up-down2img-1" src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/service3.jpg" alt="img"></img>
      <img className="up-down2img-2" src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/image-5.jpg" alt="img"></img>
      </div>
      <div className="col-12 col-lg-6">
        <div style={{ margin: '5px 5px 5px 5px' }}>
        <h1 id="ahsrtc">An Honest Service. Round the Clock.</h1>
        <p id="ahsrtcp">All round the clock, 365 days of the year - Red Scorpion Security boasts of commitment to grant our customer’s an honest service. Red Scorpion Security Solutions comprises of extreme dedication driven team of security professionals and well trained guards. With the worldwide attention being drawn to the current business scenario, Red Scorpion Security has enclosed an awfully comprehensive package that covers all clients’ desires within the field security.</p>
        <button id="ahsrtcb">Discover more</button>
        </div>
      </div>
    </div>
  </div>


  <div className="row g-0" style={{position: 'relative'}}>
      <div className="col-12 col-lg-6">
        <div className="man-4section"></div>
        <img id="background-img-security" src={graphic2} alt="g"></img>
        <img id="background-img-security1" src={graphic1} alt="g"></img>
        <img id="background-img-security2" src={graphic1} alt="g"></img>
        <img id="left-securityimg" src="https://redscorpionsecurity.in/wp-content/uploads/2023/11/guard.jpg" alt="img"></img>
      </div>
      <div className="col-12 col-lg-6">
        <span className="man-4section-part2">
        <h1 className="h1who19">We have over 18 years experience in Next Level Security</h1>
        <div className="h1who19r row g-0">
          <div className="col-12 col-lg-6">
            <h3>→ Client Focussed</h3>
            <p>Services to meet client's specific needs and aspiration through comprehensive training program and budget friendly.</p>
          </div>
          <div className="col-12 col-lg-6">
            <h3>→ We build future</h3>
            <p>Our specialised team provides services to the best of our abilities and in highest standard possible.</p>
          </div>
          <div className="col-12 col-lg-6">
            <h3>→ Efficient Building</h3>
            <p>Emphasis on recruitment, selection & training to serve our clients the state-of-art services.</p>
          </div>
          <div className="col-12 col-lg-6">
            <h3>→ Skilled Contractors</h3>
            <p>Scrutinize each aspect while hiring contractors to offer skilled and well-trained staff.</p>
          </div>
        </div>
        </span>
      </div>
  </div>

    <div className="containers">
  <h1 id="client-t">Client Testimonials</h1>
  <p id="client-p">Customers satisfaction is our utmost priority. Don't go with our words. Before you deal with us, here's what our clients have to say for us.</p>
</div>

</>
);
}

export default Navbar;