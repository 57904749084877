//import React, { useState } from "react";
import ".././assets/css/footer.css";
//import homeslider from'.././assets/img/bg_imgs.jpg';
import graphic1 from ".././assets/img/arrow-8.png";
import graphic2 from ".././assets/img/arrow-7.png";

function Footer() {

return (
<>
<footer style={{position: 'relative', overflow: 'hidden'}} >
  <div className="containers">
  <div style={{ margin: '0px 5px 0px 10px' }} className="row g-0">
    <div className="col-lg-3">
      <h2 className="footer-t">Contact Us</h2>
      <li className="footer-l">84, Sundar Nagar, New Delhi 110003</li>
      <li className="footer-l">care@redscorpionsecurity.in</li> 
      <li className="footer-l">+91-8711021102</li>
      <li className="footer-l"><img height="170px" src="https://redscorpionsecurity.in/wp-content/uploads/2015/12/logos.jpg" alt="..."/></li>
    </div>
    <div className="col-lg-2">
      <h2 className="footer-t">About us</h2>
      <li className="footer-l">Services</li>
      <li className="footer-l">Clients</li> 
      <li className="footer-l">Training</li>
      <li className="footer-l">Contact Us</li>
      <li className="footer-l">Privacy & Policy</li>
      <li className="footer-l">Jobs</li>
    </div>
    <div className="col-lg-3">
      <h2 className="footer-t">Security Guard</h2>
      <li className="footer-l">Security supervisor</li>
      <li className="footer-l">gun man</li> 
      <li className="footer-l">Aso</li>
      <li className="footer-l">Security Services in Noida</li>
      <li className="footer-l">Private Security Guards</li>
      <li className="footer-l">Security Patrol Services</li>
      <li className="footer-l">Security Guard Services Near Me</li>
      <li className="footer-l">Building Security Services</li>
    </div>
    <div className="col-lg-2">
      <h2 className="footer-t">.</h2>
      <li className="footer-l">Bouncer</li>
      <li className="footer-l">Armed PSO</li>
      <li className="footer-l">Fire Safety Expert</li> 
      <li className="footer-l">Surveillance Room</li>
      <li className="footer-l">Quick Response Team</li>
      <li className="footer-l">Security Services in Gurgaon</li>
      <li className="footer-l">Blog</li>
      <li className="footer-l">Our Locations</li>
    </div>
    <div className="col-lg-2">
      <h2 className="footer-t">about</h2>
      <li className="footer-l">Red Scorpion Security Solutions stands as a beacon of excellence, adorned with the prestigious PSARA, ISO, and CAPSI certifications, boasting a legacy of over 19 years.</li>
    </div>
  </div>
  </div>
  <hr className="solid"/>
  <div><p className="footer-mark">Best Security Service Provider In Delhi, Noida, Gurgaon & NCR.<br/>© Copyright – Red Scorpion Security Solutions Pvt Ltd</p></div>
  <img className="footer-hover-imgr1" src={graphic1} alt="..."/>
  <img className="footer-hover-imgr2" src={graphic1} alt="..."/>
  <img className="footer-hover-img1" src={graphic2} alt="..."/>

  <div className="footer-back"></div>
</footer>
</>
);
}

export default Footer;